import React, { useState } from 'react';
//import Header from '../myf-cilent-header/header.js';
import API from '../myf-api/api.js';
import { format } from 'date-fns';
import Loader from '../components/loader.js';
import { useReactToPrint } from 'react-to-print';

const BalanceFundCalculator = () => {
    const [balanceFunds, setBalanceFunds] = useState({});
    const [totalBalanceFund, setTotalBalanceFund] = useState({});
    const [totalInterestGain, setTotalInterestGain] = useState({});
    const [financialYears, setFinancialYears] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState('All');
    const [loading, setLoading] = useState(false);
    const [detailedView, setDetailedView] = useState(true);
    const url = API();
    const tableRef = React.createRef();
    const token = localStorage.getItem('accessToken');

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    const handleCalculateBF = async () => {
        setLoading(true);

        try {
            const response = await fetch(`${url}/api/calculateBF`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setBalanceFunds(data.BalanceFund);
            setTotalBalanceFund(data.totalBalanceFund)
            setTotalInterestGain(data.totalInterestGain)

            setLoading(false);

            const commonFinancialYears = Object.values(data.BalanceFund)
                .flatMap((fileData) => Object.keys(fileData))
                .filter((financialYear, index, array) => array.indexOf(financialYear) === index)
                .sort();

            setFinancialYears(['All', ...commonFinancialYears]);
        } catch (error) {
            console.error(
                'Error calculating Balance Fund:',
                error.response?.data?.errorMessage || error.message
            );
        }
    };

    const toggleDetailView = () => {
        setDetailedView(!detailedView);
    };

    const filterData = (financialYear) => {
        setSelectedFinancialYear(financialYear);
    };

    document.title = 'Ledger - Our Finance';

    return (
        <div>
            {/* <Header /> */}

            <h2>Balance Fund Calculator</h2>
            <button onClick={handleCalculateBF}>Calculate Balance Fund</button>

            <button onClick={toggleDetailView}>
                {detailedView ? 'Enable Interest Gain View' : 'Enable Detailed View'}
            </button>

            {loading ? <Loader /> :

                balanceFunds && Object.keys(balanceFunds).length > 0 && (
                    <div>
                        <h3>Financial Years:</h3>
                        <div>
                            {financialYears.map((financialYear) => (
                                <button key={financialYear} onClick={() => filterData(financialYear)}>
                                    {financialYear}
                                </button>
                            ))}
                        </div>
                        <h2>{detailedView ? 'Detailed View' : 'Interest Gain View'}</h2>

                        <button className="print-table-btn" onClick={handlePrint}>
                            Print Table
                        </button>

                        <div ref={tableRef}>
                            <h3>Balance Funds: {selectedFinancialYear}</h3>
                            {Object.keys(totalBalanceFund).map((year) => (
                                (selectedFinancialYear === year) && (
                                    <>
                                        <p>Total Balance Funds - Rs:{totalBalanceFund[selectedFinancialYear]}/-</p>
                                        <p>Total Interest Gain - Rs:{totalInterestGain[selectedFinancialYear]}/-</p>
                                    </>
                                )
                            ))}

                            <table>
                                <thead>
                                    <tr>
                                        <th>File Number</th>
                                        <th>Customer Details</th>
                                        <th>Financial Year</th>
                                        {detailedView && (
                                            <>
                                                <th>EMI Amount</th>
                                                <th>Payments</th>
                                                <th>Total Amount Paid</th>
                                                <th>Last Year BF</th>
                                                <th>Additional Investment</th>
                                                <th>Total Investment</th>
                                            </>
                                        )}
                                        <th>Interest Gain</th>
                                        {detailedView && (<th>Balance Fund</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(balanceFunds).map((fileNumber) => (
                                        Object.keys(balanceFunds[fileNumber]).map((financialYear) => (
                                            (selectedFinancialYear === 'All' || selectedFinancialYear === financialYear) && (
                                                <tr key={`${fileNumber}-${financialYear}`}>
                                                    <td>{fileNumber}</td>
                                                    <td>
                                                        <div>{balanceFunds[fileNumber][financialYear].customer.firstname}</div>
                                                        <div>Amount - Rs:{balanceFunds[fileNumber][financialYear].customer.Amount}/-</div>
                                                        <div>{balanceFunds[fileNumber][financialYear].customer.HL > 0 ? `HL - Rs:${balanceFunds[fileNumber][financialYear].customer.HL}/-` : ''}</div>
                                                        <div>Palace -{balanceFunds[fileNumber][financialYear].customer.lineOfBussiness}</div>
                                                    </td>
                                                    <td>{financialYear.replace(/(\d{2})(\d{2})-(\d{2})(\d{2})/, '$1$2-$4')}</td>
                                                    {detailedView && (
                                                        <>
                                                            <td>Rs:{balanceFunds[fileNumber][financialYear].singleInstallmentAmount}/-</td>
                                                            <td>
                                                                {(balanceFunds[fileNumber][financialYear].payments.length || balanceFunds[fileNumber][financialYear].extraPayments.length) > 0 ?
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Receipt Number</th>
                                                                                <th>Date of Payment</th>
                                                                                <th>Amount Paid</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {balanceFunds[fileNumber][financialYear].payments.map((payment) => (
                                                                                payment.amountPaid > 0 ?
                                                                                    <tr key={{ financialYear }}>
                                                                                        <td>{payment.receiptNumber}</td>
                                                                                        <td>{format(new Date(payment.dateOfPayment), 'dd-MM-yy')}</td>
                                                                                        <td>Rs:{payment.amountPaid}/-</td>
                                                                                    </tr>
                                                                                    : ''
                                                                            ))}
                                                                            {balanceFunds[fileNumber][financialYear].extraPayments.map((payment) => (
                                                                                payment.amountPaid > 0 ?
                                                                                    <tr key={{ fileNumber }}>
                                                                                        <td>{payment.receiptNumber}</td>
                                                                                        <td>{format(new Date(payment.dateOfPayment), 'dd-MM-yy')}</td>
                                                                                        <td>Rs:{payment.amountPaid}/-</td>
                                                                                    </tr>
                                                                                    : ''
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    : 'No Payment Avl'}
                                                            </td>
                                                            <td>Rs:{balanceFunds[fileNumber][financialYear].totalPaidAmount}/-</td>
                                                            <td>Rs:{balanceFunds[fileNumber][financialYear].previousYearBf}/-</td>
                                                            <td>
                                                                {balanceFunds[fileNumber][financialYear].additionalInvestment.length > 0 ?
                                                                    <>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Receipt Number</th>
                                                                                    <th>Date of Payment</th>
                                                                                    <th>Amount Paid</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {balanceFunds[fileNumber][financialYear].additionalInvestment.map((payment) => (
                                                                                    payment.amountPaid > 0 ?
                                                                                        <tr key={{ fileNumber }}>
                                                                                            <td>{payment.receiptNumber}</td>
                                                                                            <td>{format(new Date(payment.dateOfPayment), 'dd-MM-yy')}</td>
                                                                                            <td>Rs:{payment.amountPaid}/-</td>
                                                                                        </tr>
                                                                                        : ''
                                                                                ))}
                                                                            </tbody>
                                                                        </table>

                                                                        <p>Total Addl Invest Rs:{balanceFunds[fileNumber][financialYear].totalInvestment}/-</p>
                                                                    </>
                                                                    : 'No Addl Payment Avl'}
                                                            </td>
                                                            <td>Rs:{balanceFunds[fileNumber][financialYear].previousYearBf + balanceFunds[fileNumber][financialYear].totalInvestment}/-</td>
                                                        </>
                                                    )}
                                                    <td>Rs:{balanceFunds[fileNumber][financialYear].interestGain}/-</td>
                                                    {detailedView && (
                                                        <td>Rs:{balanceFunds[fileNumber][financialYear].balanceFund}/-</td>
                                                    )}
                                                </tr>
                                            )
                                        ))
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default BalanceFundCalculator;
