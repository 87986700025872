import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/layout.js';
import Login from './myf-cilent-login/login.js';
import Home from './myf-cilent-home/home.js';
import AddCustomer from './myf-cilent-add-customer/addCustomer.js';
import CustomerPendingList from './myf-cilent-customer-pendinglist/customerPendingList.js';
import CustomerInstallmentDetails from './myf-cilent-customer-installment-details/customerInstallmentDetails.js';
import AddPayment from './myf-cilent-add-payment/addPaymentDetails.js';
import PaymentDetails from './myf-cilent-add-payment/pay.js';
import CustomerList from './myf-cilent-my-customers.js/myCustomers.js';
import BalanceFundCalculator from './myf-cilent-ledger/Ledger.js';
import VehicleManagement from './myf-cilent-vehicle-management/vehicleManagement.js';
import BFCalculator from './myf-cilent-CommonFeatures/FinanceBFCalculator.js';
import API from './myf-api/api.js';
import Loader from './components/loader.js';
import Header from './myf-cilent-header/header.js'

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const url = API();
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const isDevelopment = process.env.NODE_ENV === "development";

  useEffect(() => {
    const checkAuthentication = async () => {
      // if (isDevelopment) {
      //   setIsAuthenticated(true);
      //   setLoading(false);
      //   return;
      // }

      const token = localStorage.getItem('accessToken');

      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        navigate('/', { state: { from: location }, replace: true });
        return;
      }

      try {
        setLoading(true);

        const response = await fetch(`${url}/validateToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessToken: token }),
        });

        const result = await response.json();

        if (result.success) {
          setIsAuthenticated(true);
          setLoading(false);
          setUserRole(result.userRole);
        } else {
          const token = localStorage.removeItem('accessToken');
          if (token === undefined) {
            window.alert("Welcome To Our Finance Portal");
          };
          setIsAuthenticated(false);
          setLoading(false);
          navigate('/', { state: { from: location }, replace: true });
        }
      } catch (error) {
        const token = localStorage.removeItem('accessToken');
        if (token === undefined) {
          window.alert("Welcome To Our Finance Portal");
        };
        setIsAuthenticated(false);
        setLoading(false);
        navigate('/', { state: { from: location }, replace: true });
      }
    };

    checkAuthentication();
  }, [location, navigate, url, isDevelopment, userRole]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    setLoading(false);
    navigate('/', { replace: true });
  };

  const shouldRenderHeader = location.pathname !== '/';

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {shouldRenderHeader && <Header userRole={userRole} />}
          <Routes>
            <Route path="/" element={<Layout onLogout={handleLogout} />}>
              <Route path="/" element={<Login onLogin={() => setIsAuthenticated(true)} />} />
              {isAuthenticated && (userRole === 'Owner' || userRole === 'Accounts' || userRole === 'Line Manager') && (
                <>
                  <Route path="home" element={<Home />} />
                  <Route path="pendingList" element={<CustomerPendingList />} />
                  <Route path="myCustomers" element={<CustomerList />} />
                  <Route path="vehicleManagement" element={<VehicleManagement userRole={userRole}/>} />
                </>
              )}

              {isAuthenticated && (userRole === 'Owner' || userRole === 'Accounts') && (
                <>
                  <Route path="addCustomer" element={<AddCustomer />} />
                  <Route path="customerDetails" element={<CustomerInstallmentDetails userRole={userRole} />} />
                  <Route path="financeBFCalculator" element={<BFCalculator />} />
                </>
              )}



              {isAuthenticated && (userRole === 'Owner') && (
                <>
                  <Route path="ledger" element={<BalanceFundCalculator />} />
                  <Route path="addPaymentDetails" element={<AddPayment />} />
                  <Route path="pay" element={<PaymentDetails />} />
                </>
              )}
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
