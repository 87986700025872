import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import API from '../myf-api/api.js';
//import Header from '../myf-cilent-header/header.js';
import Loader from '../components/loader.js'
import './myCustomers.css';

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const url = API();
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch(`${url}/api/allCustomers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setLoading(false);
                setCustomers(data.customers);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, [url, token]);

    const filteredCustomers = customers.filter(customer =>
        customer.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.bikeModel.toLowerCase().includes(searchQuery.toLowerCase())
    );

    document.title = 'My Customers - Our Finance';

    return (
        <div>
            {/* <Header /> */}
            <h3>Total Customers: {customers.length}</h3>
            <div className="filter-container">
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search by name or bike details"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            {loading ? <Loader /> :
                <div className="customer-list-container">
                    <table className="desktop-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>File Number</th>
                                <th>Customer Name</th>
                                <th>Finance Amount</th>
                                <th>Total Installemnts</th>
                                <th>Installemnt Date</th>
                                <th>Bike Details</th>
                                <th>Agent</th>
                                <th>Address</th>
                                <th>Phone Number</th>
                                <th>Guarantor</th>
                                <th>Guarantor Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCustomers.map((customer, index) => (
                                <tr key={customer._id}>
                                    <td>{index + 1}</td>
                                    <td>{customer.fileNumber}</td>
                                    <td>{customer.firstname}</td>
                                    <td>Rs:{customer.amount}/-</td>
                                    <td>{customer.installments}</td>
                                    <td>{format(new Date(customer.date.split('T')[0]), 'dd-MM-yyyy')}</td>
                                    <td>{customer.bikeModel}</td>
                                    <td>{customer.agentName}</td>
                                    <td>{customer.address}</td>
                                    <td>{customer.phoneNumber}</td>
                                    <td>{customer.nominee}</td>
                                    <td>{customer.guarantorAddressPhoneNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mobile-list">
                        {filteredCustomers.map((customer, index) => (
                            <div key={customer._id} className="customer-info">
                                <p>S.No: {index + 1}</p>
                                <h4>Name: {customer.firstname}</h4>
                                <p>Mobile Number: {customer.phoneNumber}</p>
                                <p>File Number: {customer.fileNumber}</p>
                                <p>Address: {customer.address}</p>
                                <p>Amount: Rs.{customer.amount}/-</p>
                                <p>Instalments: {customer.installments}</p>
                                <p>Bike Deatils: {customer.bikeModel}</p>
                                <p>Agent: {customer.agentName}</p>
                                <hr />
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default CustomerList;
